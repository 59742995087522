import React from 'react'
import Blockquote from '../components/Main/Blockquote'
import ImageCallout from '../components/Main/ImageCallout'
import FeaturedContent from '../components/Main/FeaturedContent'
import Page from '../components/Page'

const Homepage = props => {
  //Hero Section fields
  const {
    intro_image: {
      url: introImage,
      alt: alt,
      height: height,
      width: width,
      id: imageId
    },
    intro_heading: introHeading,
    intro_paragraph: introParagraph,

    //Blockquote fields
    blockquote_quote: quote,
    blockquote_byline: byline,
    blockquote_link: { url: linkUrl, title: linkText },

    // callout_upper_images: calloutImages,
    callout_upper_images: calloutImages,
    callout_upper_heading: calloutHeading,
    callout_upper_sub_heading: calloutSubheading,
    callout_upper_paragraph: calloutParagraph,
    callout_upper_link: { url: calloutUrl, title: calloutLinkTitle },
    callout_upper_sub_text: calloutSubText,
    callout_upper_column_order: colOrder,

    //callout_about_image:
    callout_about_image: { url: aboutImageUrl },
    callout_about_image_caption: aboutCaption,
    callout_about_heading: aboutHeading,
    callout_about_sub_heading: aboutSubheading,
    callout_about_paragraph: aboutParagraph,
    callout_about_link: {
      url: aboutUrl,
      title: aboutLinkTitle,
      target: target
    },
    callout_about_link_2: {
      url: aboutUrl2,
      title: aboutLinkTitle2,
      target: target2
    },
    callout_about_column_order: aboutColOrder,
    intro_link_icon: aboutIcon,
    sr_only_about: srOnlyAbout,
    sr_only_about_2: srOnlyAbout2,

    //callout_lower_image:
    callout_lower_image: { url: lowerImageUrl },
    callout_lower_image_caption: lowerCaption,
    callout_lower_heading: lowerHeading,
    callout_lower_sub_heading: lowerSubheading,
    callout_lower_paragraph: lowerParagraph,
    callout_lower_link: { url: lowerUrl, title: lowerLinkTitle },
    callout_lower_column_order: lowerColOrder,
    intro_link_icon: lowerIcon,
    sr_only_lower: srOnlyLower,

    //Featured content home_link_column repeater
    home_link_column: homeColumns
  } = props.page.acf

  return (
    <React.Fragment>
      <Page
        body={introParagraph}
        title={introHeading}
        bgImage={introImage}
        bgId={imageId}
        alt={alt}
        height={height}
        width={width}
        slug=''
      >
        {/*  <HeroFullbleed
            title={introHeading}
            body={introParagraph}
            bgImage={introImage}
          />*/}
        <main id='content'>
          <Blockquote
            quote={quote}
            byline={byline}
            linkUrl={linkUrl}
            linkText={linkText}
            target={target}
          />

          <ImageCallout
            title={aboutHeading}
            body={aboutParagraph}
            link={aboutUrl}
            link2={aboutUrl2}
            button={aboutLinkTitle}
            button2={aboutLinkTitle2}
            image={aboutImageUrl}
            srOnly={srOnlyAbout}
            srOnly2={srOnlyAbout2}
          />
          <ImageCallout
            reversed
            title={lowerHeading}
            body={lowerParagraph}
            link={lowerUrl}
            button={lowerLinkTitle}
            image={lowerImageUrl}
            srOnly={srOnlyLower}
          />
          <FeaturedContent columns={homeColumns} />
        </main>
      </Page>
    </React.Fragment>
  )
}

export default Homepage
