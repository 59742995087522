import React from 'react'
import Link from 'next/link'
import { useInView } from 'react-intersection-observer'

const Blockquote = props => {
  const [slideUpRef, isInView] = useInView({ triggerOnce: true })

  const {
    quote = 'Blockquote Text Here',
    byline,
    linkText,
    linkUrl,
    target
  } = props

  const renderByline = () =>
    byline && <p className='blockquote__byline'>{byline}</p>

  return (
    <div id='skip' className='container-fluid blockquote'>
      {/*uses .animate to start animation*/}

      <div className='row'>
        <div
          ref={slideUpRef}
          className={`col-12  animation ${isInView ? 'slide-up' : ''}`}
        >
          <p className={`blockquote__quote`}>{quote}</p>

          {renderByline()}

          {/* If there is a linkUrl, then it will show the button, otherwise a button won't display*/}
          {linkUrl ? (
            <p className={`blockquote__link ${isInView ? 'slide-up' : ''}`}>
              {target == '_blank' ? (
                <a className='link' href={linkUrl} target='_blank'>
                  {linkText}
                </a>
              ) : (
                <Link href={linkUrl} passHref>
                  <a className='link' target='_self'>
                    {linkText}
                  </a>
                </Link>
              )}
            </p>
          ) : (
            ''
          )}
        </div>
      </div>

      <style jsx>{`
        .slide-up {
          animation-name: slide-up;
          animation-duration: 1s;
          animation-iteration-count: 1;
        }

        .blockquote__quote {
          padding: 0;
        }

        .blockquote__link {
          animation-delay: 100ms;
          padding: 0;
        }

        @keyframes slide-up {
          from {
            opacity: 0;
            visibility: hidden;
            transform: translateY(50px);
          }

          to {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  )
}

export default Blockquote
