import React from 'react'
import Link from 'next/link'
import { useInView } from 'react-intersection-observer'

import LazyImage from '../LazyImage'
// ==========================================================================
// -If the ImageCallout acf field is in a repeater array, make sure to give it the array prop
// -this component has 3 positions: default (no prop required), reversed, and centered
// ==========================================================================

const ImageCallout = props => {
  //Changes the alignment of components to left or right and sets their slide in animation
  const [toLeftRef, toLeftInView] = useInView({ triggerOnce: true })
  const [toRightRef, toRightInView] = useInView({ triggerOnce: true })

  const {
    title,
    body,
    link = '/',
    link2,
    button,
    button2,
    image = 'http://placekitten.com/1280/720',
    alt = '',
    srOnly,
    srOnly2
  } = props

  const renderImages = items =>
    items.map(item => {
      const data = item.image

      return (
        <LazyImage
          imageClass='callout-image'
          key={data.id}
          src={data.url}
          alt={`${data.alt ? data.alt : ''}`}
        />
      )
    })

  return (
    <div
      className={`${props.reversed ? 'reversed' : ''} ${
        props.centered ? 'centered' : ''
      } container-fluid image-callout middle-callout`}
    >
      <div className='row align-items-center'>
        <div
          ref={toLeftRef}
          className={`animation ${toLeftInView ? 'animate-slide-left' : ''} ${
            props.centered ? '' : ''
          } col-12 col-lg-9 image-callout__image`}
        >
          {/*If the acf field has an image reapeater, give ImageCallout the array prop*/}
          {props.array ? (
            renderImages(props.images)
          ) : (
            <LazyImage imageClass='callout-image' src={image} alt={alt} />
          )}
        </div>
        <div
          ref={toRightRef}
          className={`${
            toRightInView ? 'animate-slide-right' : ''
          } middle__callout-text col-10 col-lg-6 offset-1 offset-lg-0 d-flex flex-column justify-content-center align-items-center animation`}
        >
          <div className='bluebox'>
            <h2
              className='heading2 image-callout__heading'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div dangerouslySetInnerHTML={{ __html: body }} />

            {button ? (
              <p className='image-callout__link'>
                {props.noSlug ? (
                  <a href={link} className='link-holder' target='_self'>
                    <span className='link link-text' target='_self'>
                      {button}
                      {srOnly ? <span className='sr-only'>{srOnly}</span> : ''}
                    </span>
                  </a>
                ) : (
                  <Link href={link} passHref>
                    <a className='link-holder' target='_self'>
                      <span className='link link-text' target='_self'>
                        {button}
                        {srOnly ? (
                          <span className='sr-only'>{srOnly}</span>
                        ) : (
                          ''
                        )}
                      </span>
                    </a>
                  </Link>
                )}
              </p>
            ) : (
              ''
            )}

            {button2 ? (
              <p className='image-callout__link' style={{ margin: '0 auto' }}>
                <Link href={link2} passHref>
                  <a className='link-holder' target='_self'>
                    <span
                      className='link link-text'
                      target='_self'
                      // style={{ paddingRight: '52px' }}
                    >
                      {button2}
                      {srOnly2 ? (
                        <span className='sr-only'>{srOnly2}</span>
                      ) : (
                        ''
                      )}
                    </span>
                  </a>
                </Link>
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .row {
          min-height: 90vh;
        }
        .centered .animate-slide-left {
          margin-left: -25%;
          right: -25%;
        }

        @media (max-width: 991.98px) {
          .row {
            min-height: 80vh;
          }

          .image-callout__image,
          .image-callout__image img {
            min-height: 50vh;
            align-items: flex-end;
            right: 0;
          }
        }

        @media (max-width: 1199.98px) {
          .image-callout__image,
          .image-callout__image img {
            padding: 0;
            align-items: flex-end;
          }
        }

        .image-callout__image {
          margin: 0;
          padding: 0;
          overflow: hidden;
        }
      `}</style>
    </div>
  )
}

export default ImageCallout
