import React from 'react'
import Head from 'next/head'
import { useInView } from 'react-intersection-observer'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

const Page = props => {
  //passing props through components to Index Page
  const {
    title,
    body,
    heroTitle,
    heroBody,
    bgImage,
    bgVideo,
    alt,
    height,
    width,
    videoHeight,
    videoWidth,
    slug,
    left,
    disabledArrow,
    hideDate
  } = props
  const [ref, inView] = useInView({ rootMargin: '-1000px 0  500px 0' })

  return (
    <React.Fragment>
      <Header {...props} />
      <div id='page' className='site'>
        {props.children}
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default Page
